/**
 * @fileoverview gRPC-Web generated client stub for cargo_type
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/cargo_type/cargo_type.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_cargo_type_cargo_type_pb from '../../protobuf/cargo_type/cargo_type_pb';
import * as protobuf_model_merged_pb from '../../protobuf/model/merged_pb';


export class CargoTypeServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/cargo_type.CargoTypeService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_cargo_type_cargo_type_pb.CreateCargoTypeRequest,
    protobuf_model_merged_pb.CargoTypeResponse,
    (request: protobuf_cargo_type_cargo_type_pb.CreateCargoTypeRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.CargoTypeResponse.deserializeBinary
  );

  create(
    request: protobuf_cargo_type_cargo_type_pb.CreateCargoTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.CargoTypeResponse>;

  create(
    request: protobuf_cargo_type_cargo_type_pb.CreateCargoTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CargoTypeResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.CargoTypeResponse>;

  create(
    request: protobuf_cargo_type_cargo_type_pb.CreateCargoTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CargoTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cargo_type.CargoTypeService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cargo_type.CargoTypeService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/cargo_type.CargoTypeService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_cargo_type_cargo_type_pb.UpdateCargoTypeRequest,
    protobuf_model_merged_pb.CargoTypeResponse,
    (request: protobuf_cargo_type_cargo_type_pb.UpdateCargoTypeRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.CargoTypeResponse.deserializeBinary
  );

  update(
    request: protobuf_cargo_type_cargo_type_pb.UpdateCargoTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.CargoTypeResponse>;

  update(
    request: protobuf_cargo_type_cargo_type_pb.UpdateCargoTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CargoTypeResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.CargoTypeResponse>;

  update(
    request: protobuf_cargo_type_cargo_type_pb.UpdateCargoTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CargoTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cargo_type.CargoTypeService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cargo_type.CargoTypeService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/cargo_type.CargoTypeService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_cargo_type_cargo_type_pb.GetCargoTypesRequest,
    protobuf_cargo_type_cargo_type_pb.GetCargoTypesResponse,
    (request: protobuf_cargo_type_cargo_type_pb.GetCargoTypesRequest) => {
      return request.serializeBinary();
    },
    protobuf_cargo_type_cargo_type_pb.GetCargoTypesResponse.deserializeBinary
  );

  get(
    request: protobuf_cargo_type_cargo_type_pb.GetCargoTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_cargo_type_cargo_type_pb.GetCargoTypesResponse>;

  get(
    request: protobuf_cargo_type_cargo_type_pb.GetCargoTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_cargo_type_cargo_type_pb.GetCargoTypesResponse) => void): grpcWeb.ClientReadableStream<protobuf_cargo_type_cargo_type_pb.GetCargoTypesResponse>;

  get(
    request: protobuf_cargo_type_cargo_type_pb.GetCargoTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_cargo_type_cargo_type_pb.GetCargoTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cargo_type.CargoTypeService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cargo_type.CargoTypeService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/cargo_type.CargoTypeService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_cargo_type_cargo_type_pb.DeleteCargoTypesRequest,
    protobuf_cargo_type_cargo_type_pb.DeleteCargoTypesResponse,
    (request: protobuf_cargo_type_cargo_type_pb.DeleteCargoTypesRequest) => {
      return request.serializeBinary();
    },
    protobuf_cargo_type_cargo_type_pb.DeleteCargoTypesResponse.deserializeBinary
  );

  delete(
    request: protobuf_cargo_type_cargo_type_pb.DeleteCargoTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_cargo_type_cargo_type_pb.DeleteCargoTypesResponse>;

  delete(
    request: protobuf_cargo_type_cargo_type_pb.DeleteCargoTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_cargo_type_cargo_type_pb.DeleteCargoTypesResponse) => void): grpcWeb.ClientReadableStream<protobuf_cargo_type_cargo_type_pb.DeleteCargoTypesResponse>;

  delete(
    request: protobuf_cargo_type_cargo_type_pb.DeleteCargoTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_cargo_type_cargo_type_pb.DeleteCargoTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cargo_type.CargoTypeService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cargo_type.CargoTypeService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/cargo_type.CargoTypeService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_cargo_type_cargo_type_pb.ListCargoTypesRequest,
    protobuf_cargo_type_cargo_type_pb.ListCargoTypesResponse,
    (request: protobuf_cargo_type_cargo_type_pb.ListCargoTypesRequest) => {
      return request.serializeBinary();
    },
    protobuf_cargo_type_cargo_type_pb.ListCargoTypesResponse.deserializeBinary
  );

  list(
    request: protobuf_cargo_type_cargo_type_pb.ListCargoTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_cargo_type_cargo_type_pb.ListCargoTypesResponse>;

  list(
    request: protobuf_cargo_type_cargo_type_pb.ListCargoTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_cargo_type_cargo_type_pb.ListCargoTypesResponse) => void): grpcWeb.ClientReadableStream<protobuf_cargo_type_cargo_type_pb.ListCargoTypesResponse>;

  list(
    request: protobuf_cargo_type_cargo_type_pb.ListCargoTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_cargo_type_cargo_type_pb.ListCargoTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cargo_type.CargoTypeService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cargo_type.CargoTypeService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorExport = new grpcWeb.MethodDescriptor(
    '/cargo_type.CargoTypeService/Export',
    grpcWeb.MethodType.UNARY,
    protobuf_cargo_type_cargo_type_pb.ExportCargoTypesRequest,
    protobuf_cargo_type_cargo_type_pb.ExportCargoTypesResponse,
    (request: protobuf_cargo_type_cargo_type_pb.ExportCargoTypesRequest) => {
      return request.serializeBinary();
    },
    protobuf_cargo_type_cargo_type_pb.ExportCargoTypesResponse.deserializeBinary
  );

  export(
    request: protobuf_cargo_type_cargo_type_pb.ExportCargoTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_cargo_type_cargo_type_pb.ExportCargoTypesResponse>;

  export(
    request: protobuf_cargo_type_cargo_type_pb.ExportCargoTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_cargo_type_cargo_type_pb.ExportCargoTypesResponse) => void): grpcWeb.ClientReadableStream<protobuf_cargo_type_cargo_type_pb.ExportCargoTypesResponse>;

  export(
    request: protobuf_cargo_type_cargo_type_pb.ExportCargoTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_cargo_type_cargo_type_pb.ExportCargoTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cargo_type.CargoTypeService/Export',
        request,
        metadata || {},
        this.methodDescriptorExport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cargo_type.CargoTypeService/Export',
    request,
    metadata || {},
    this.methodDescriptorExport);
  }

}

