// source: protobuf/cargo_type/cargo_type.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var protobuf_validate_validate_pb = require('../../protobuf/validate/validate_pb.js');
goog.object.extend(proto, protobuf_validate_validate_pb);
var protobuf_filter_counters_pb = require('../../protobuf/filter/counters_pb.js');
goog.object.extend(proto, protobuf_filter_counters_pb);
var protobuf_filter_export_pb = require('../../protobuf/filter/export_pb.js');
goog.object.extend(proto, protobuf_filter_export_pb);
var protobuf_filter_request_pb = require('../../protobuf/filter/request_pb.js');
goog.object.extend(proto, protobuf_filter_request_pb);
var protobuf_model_merged_pb = require('../../protobuf/model/merged_pb.js');
goog.object.extend(proto, protobuf_model_merged_pb);
goog.exportSymbol('proto.cargo_type.CreateCargoTypeDocTypeRequest', null, global);
goog.exportSymbol('proto.cargo_type.CreateCargoTypeRequest', null, global);
goog.exportSymbol('proto.cargo_type.DeleteCargoTypesRequest', null, global);
goog.exportSymbol('proto.cargo_type.DeleteCargoTypesResponse', null, global);
goog.exportSymbol('proto.cargo_type.ExportCargoTypesRequest', null, global);
goog.exportSymbol('proto.cargo_type.ExportCargoTypesResponse', null, global);
goog.exportSymbol('proto.cargo_type.GetCargoTypesRequest', null, global);
goog.exportSymbol('proto.cargo_type.GetCargoTypesResponse', null, global);
goog.exportSymbol('proto.cargo_type.ListCargoTypesRequest', null, global);
goog.exportSymbol('proto.cargo_type.ListCargoTypesResponse', null, global);
goog.exportSymbol('proto.cargo_type.UpdateCargoTypeDocTypeRequest', null, global);
goog.exportSymbol('proto.cargo_type.UpdateCargoTypeRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cargo_type.CreateCargoTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cargo_type.CreateCargoTypeRequest.repeatedFields_, null);
};
goog.inherits(proto.cargo_type.CreateCargoTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cargo_type.CreateCargoTypeRequest.displayName = 'proto.cargo_type.CreateCargoTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cargo_type.UpdateCargoTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cargo_type.UpdateCargoTypeRequest.repeatedFields_, null);
};
goog.inherits(proto.cargo_type.UpdateCargoTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cargo_type.UpdateCargoTypeRequest.displayName = 'proto.cargo_type.UpdateCargoTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cargo_type.GetCargoTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cargo_type.GetCargoTypesRequest.repeatedFields_, null);
};
goog.inherits(proto.cargo_type.GetCargoTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cargo_type.GetCargoTypesRequest.displayName = 'proto.cargo_type.GetCargoTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cargo_type.GetCargoTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cargo_type.GetCargoTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cargo_type.GetCargoTypesResponse.displayName = 'proto.cargo_type.GetCargoTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cargo_type.DeleteCargoTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cargo_type.DeleteCargoTypesRequest.repeatedFields_, null);
};
goog.inherits(proto.cargo_type.DeleteCargoTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cargo_type.DeleteCargoTypesRequest.displayName = 'proto.cargo_type.DeleteCargoTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cargo_type.DeleteCargoTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cargo_type.DeleteCargoTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cargo_type.DeleteCargoTypesResponse.displayName = 'proto.cargo_type.DeleteCargoTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cargo_type.ListCargoTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cargo_type.ListCargoTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cargo_type.ListCargoTypesRequest.displayName = 'proto.cargo_type.ListCargoTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cargo_type.ListCargoTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cargo_type.ListCargoTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.cargo_type.ListCargoTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cargo_type.ListCargoTypesResponse.displayName = 'proto.cargo_type.ListCargoTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cargo_type.ExportCargoTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cargo_type.ExportCargoTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cargo_type.ExportCargoTypesRequest.displayName = 'proto.cargo_type.ExportCargoTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cargo_type.ExportCargoTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cargo_type.ExportCargoTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cargo_type.ExportCargoTypesResponse.displayName = 'proto.cargo_type.ExportCargoTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cargo_type.CreateCargoTypeDocTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cargo_type.CreateCargoTypeDocTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cargo_type.CreateCargoTypeDocTypeRequest.displayName = 'proto.cargo_type.CreateCargoTypeDocTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cargo_type.UpdateCargoTypeDocTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cargo_type.UpdateCargoTypeDocTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cargo_type.UpdateCargoTypeDocTypeRequest.displayName = 'proto.cargo_type.UpdateCargoTypeDocTypeRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cargo_type.CreateCargoTypeRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cargo_type.CreateCargoTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cargo_type.CreateCargoTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.CreateCargoTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adr: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    ansaControl: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    trailerTypeId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    temperatureRegime: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cargoTypeDocTypesList: jspb.Message.toObjectList(msg.getCargoTypeDocTypesList(),
    proto.cargo_type.CreateCargoTypeDocTypeRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cargo_type.CreateCargoTypeRequest}
 */
proto.cargo_type.CreateCargoTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cargo_type.CreateCargoTypeRequest;
  return proto.cargo_type.CreateCargoTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cargo_type.CreateCargoTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cargo_type.CreateCargoTypeRequest}
 */
proto.cargo_type.CreateCargoTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdr(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnsaControl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerTypeId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemperatureRegime(value);
      break;
    case 7:
      var value = new proto.cargo_type.CreateCargoTypeDocTypeRequest;
      reader.readMessage(value,proto.cargo_type.CreateCargoTypeDocTypeRequest.deserializeBinaryFromReader);
      msg.addCargoTypeDocTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cargo_type.CreateCargoTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cargo_type.CreateCargoTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.CreateCargoTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdr();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAnsaControl();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTrailerTypeId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTemperatureRegime();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCargoTypeDocTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.cargo_type.CreateCargoTypeDocTypeRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cargo_type.CreateCargoTypeRequest} returns this
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_id = 2;
 * @return {string}
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cargo_type.CreateCargoTypeRequest} returns this
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool adr = 3;
 * @return {boolean}
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.getAdr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cargo_type.CreateCargoTypeRequest} returns this
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.setAdr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool ansa_control = 4;
 * @return {boolean}
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.getAnsaControl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cargo_type.CreateCargoTypeRequest} returns this
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.setAnsaControl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string trailer_type_id = 5;
 * @return {string}
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.getTrailerTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cargo_type.CreateCargoTypeRequest} returns this
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.setTrailerTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string temperature_regime = 6;
 * @return {string}
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.getTemperatureRegime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cargo_type.CreateCargoTypeRequest} returns this
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.setTemperatureRegime = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated CreateCargoTypeDocTypeRequest cargo_type_doc_types = 7;
 * @return {!Array<!proto.cargo_type.CreateCargoTypeDocTypeRequest>}
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.getCargoTypeDocTypesList = function() {
  return /** @type{!Array<!proto.cargo_type.CreateCargoTypeDocTypeRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cargo_type.CreateCargoTypeDocTypeRequest, 7));
};


/**
 * @param {!Array<!proto.cargo_type.CreateCargoTypeDocTypeRequest>} value
 * @return {!proto.cargo_type.CreateCargoTypeRequest} returns this
*/
proto.cargo_type.CreateCargoTypeRequest.prototype.setCargoTypeDocTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.cargo_type.CreateCargoTypeDocTypeRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cargo_type.CreateCargoTypeDocTypeRequest}
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.addCargoTypeDocTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.cargo_type.CreateCargoTypeDocTypeRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cargo_type.CreateCargoTypeRequest} returns this
 */
proto.cargo_type.CreateCargoTypeRequest.prototype.clearCargoTypeDocTypesList = function() {
  return this.setCargoTypeDocTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cargo_type.UpdateCargoTypeRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cargo_type.UpdateCargoTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cargo_type.UpdateCargoTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.UpdateCargoTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adr: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    ansaControl: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    trailerTypeId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    temperatureRegime: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cargoTypeDocTypesList: jspb.Message.toObjectList(msg.getCargoTypeDocTypesList(),
    proto.cargo_type.UpdateCargoTypeDocTypeRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cargo_type.UpdateCargoTypeRequest}
 */
proto.cargo_type.UpdateCargoTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cargo_type.UpdateCargoTypeRequest;
  return proto.cargo_type.UpdateCargoTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cargo_type.UpdateCargoTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cargo_type.UpdateCargoTypeRequest}
 */
proto.cargo_type.UpdateCargoTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdr(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnsaControl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerTypeId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemperatureRegime(value);
      break;
    case 8:
      var value = new proto.cargo_type.UpdateCargoTypeDocTypeRequest;
      reader.readMessage(value,proto.cargo_type.UpdateCargoTypeDocTypeRequest.deserializeBinaryFromReader);
      msg.addCargoTypeDocTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cargo_type.UpdateCargoTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cargo_type.UpdateCargoTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.UpdateCargoTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdr();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAnsaControl();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getTrailerTypeId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTemperatureRegime();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCargoTypeDocTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.cargo_type.UpdateCargoTypeDocTypeRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cargo_type.UpdateCargoTypeRequest} returns this
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cargo_type.UpdateCargoTypeRequest} returns this
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string category_id = 3;
 * @return {string}
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cargo_type.UpdateCargoTypeRequest} returns this
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool adr = 4;
 * @return {boolean}
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.getAdr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cargo_type.UpdateCargoTypeRequest} returns this
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.setAdr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool ansa_control = 5;
 * @return {boolean}
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.getAnsaControl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cargo_type.UpdateCargoTypeRequest} returns this
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.setAnsaControl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string trailer_type_id = 6;
 * @return {string}
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.getTrailerTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cargo_type.UpdateCargoTypeRequest} returns this
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.setTrailerTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string temperature_regime = 7;
 * @return {string}
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.getTemperatureRegime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cargo_type.UpdateCargoTypeRequest} returns this
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.setTemperatureRegime = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated UpdateCargoTypeDocTypeRequest cargo_type_doc_types = 8;
 * @return {!Array<!proto.cargo_type.UpdateCargoTypeDocTypeRequest>}
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.getCargoTypeDocTypesList = function() {
  return /** @type{!Array<!proto.cargo_type.UpdateCargoTypeDocTypeRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cargo_type.UpdateCargoTypeDocTypeRequest, 8));
};


/**
 * @param {!Array<!proto.cargo_type.UpdateCargoTypeDocTypeRequest>} value
 * @return {!proto.cargo_type.UpdateCargoTypeRequest} returns this
*/
proto.cargo_type.UpdateCargoTypeRequest.prototype.setCargoTypeDocTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.cargo_type.UpdateCargoTypeDocTypeRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cargo_type.UpdateCargoTypeDocTypeRequest}
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.addCargoTypeDocTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.cargo_type.UpdateCargoTypeDocTypeRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cargo_type.UpdateCargoTypeRequest} returns this
 */
proto.cargo_type.UpdateCargoTypeRequest.prototype.clearCargoTypeDocTypesList = function() {
  return this.setCargoTypeDocTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cargo_type.GetCargoTypesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cargo_type.GetCargoTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cargo_type.GetCargoTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cargo_type.GetCargoTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.GetCargoTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cargo_type.GetCargoTypesRequest}
 */
proto.cargo_type.GetCargoTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cargo_type.GetCargoTypesRequest;
  return proto.cargo_type.GetCargoTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cargo_type.GetCargoTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cargo_type.GetCargoTypesRequest}
 */
proto.cargo_type.GetCargoTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cargo_type.GetCargoTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cargo_type.GetCargoTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cargo_type.GetCargoTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.GetCargoTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.cargo_type.GetCargoTypesRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cargo_type.GetCargoTypesRequest} returns this
 */
proto.cargo_type.GetCargoTypesRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cargo_type.GetCargoTypesRequest} returns this
 */
proto.cargo_type.GetCargoTypesRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cargo_type.GetCargoTypesRequest} returns this
 */
proto.cargo_type.GetCargoTypesRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cargo_type.GetCargoTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cargo_type.GetCargoTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cargo_type.GetCargoTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.GetCargoTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsMap: (f = msg.getItemsMap()) ? f.toObject(includeInstance, proto.model.CargoTypeResponse.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cargo_type.GetCargoTypesResponse}
 */
proto.cargo_type.GetCargoTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cargo_type.GetCargoTypesResponse;
  return proto.cargo_type.GetCargoTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cargo_type.GetCargoTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cargo_type.GetCargoTypesResponse}
 */
proto.cargo_type.GetCargoTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getItemsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.model.CargoTypeResponse.deserializeBinaryFromReader, "", new proto.model.CargoTypeResponse());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cargo_type.GetCargoTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cargo_type.GetCargoTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cargo_type.GetCargoTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.GetCargoTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.model.CargoTypeResponse.serializeBinaryToWriter);
  }
};


/**
 * map<string, model.CargoTypeResponse> items = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.model.CargoTypeResponse>}
 */
proto.cargo_type.GetCargoTypesResponse.prototype.getItemsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.model.CargoTypeResponse>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.model.CargoTypeResponse));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.cargo_type.GetCargoTypesResponse} returns this
 */
proto.cargo_type.GetCargoTypesResponse.prototype.clearItemsMap = function() {
  this.getItemsMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cargo_type.DeleteCargoTypesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cargo_type.DeleteCargoTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cargo_type.DeleteCargoTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cargo_type.DeleteCargoTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.DeleteCargoTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cargo_type.DeleteCargoTypesRequest}
 */
proto.cargo_type.DeleteCargoTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cargo_type.DeleteCargoTypesRequest;
  return proto.cargo_type.DeleteCargoTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cargo_type.DeleteCargoTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cargo_type.DeleteCargoTypesRequest}
 */
proto.cargo_type.DeleteCargoTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cargo_type.DeleteCargoTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cargo_type.DeleteCargoTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cargo_type.DeleteCargoTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.DeleteCargoTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.cargo_type.DeleteCargoTypesRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cargo_type.DeleteCargoTypesRequest} returns this
 */
proto.cargo_type.DeleteCargoTypesRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cargo_type.DeleteCargoTypesRequest} returns this
 */
proto.cargo_type.DeleteCargoTypesRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cargo_type.DeleteCargoTypesRequest} returns this
 */
proto.cargo_type.DeleteCargoTypesRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cargo_type.DeleteCargoTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cargo_type.DeleteCargoTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cargo_type.DeleteCargoTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.DeleteCargoTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cargo_type.DeleteCargoTypesResponse}
 */
proto.cargo_type.DeleteCargoTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cargo_type.DeleteCargoTypesResponse;
  return proto.cargo_type.DeleteCargoTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cargo_type.DeleteCargoTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cargo_type.DeleteCargoTypesResponse}
 */
proto.cargo_type.DeleteCargoTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cargo_type.DeleteCargoTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cargo_type.DeleteCargoTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cargo_type.DeleteCargoTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.DeleteCargoTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cargo_type.ListCargoTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cargo_type.ListCargoTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cargo_type.ListCargoTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.ListCargoTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cargo_type.ListCargoTypesRequest}
 */
proto.cargo_type.ListCargoTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cargo_type.ListCargoTypesRequest;
  return proto.cargo_type.ListCargoTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cargo_type.ListCargoTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cargo_type.ListCargoTypesRequest}
 */
proto.cargo_type.ListCargoTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cargo_type.ListCargoTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cargo_type.ListCargoTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cargo_type.ListCargoTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.ListCargoTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Request filter = 1;
 * @return {?proto.filter.Request}
 */
proto.cargo_type.ListCargoTypesRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 1));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.cargo_type.ListCargoTypesRequest} returns this
*/
proto.cargo_type.ListCargoTypesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cargo_type.ListCargoTypesRequest} returns this
 */
proto.cargo_type.ListCargoTypesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cargo_type.ListCargoTypesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cargo_type.ListCargoTypesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cargo_type.ListCargoTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cargo_type.ListCargoTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cargo_type.ListCargoTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.ListCargoTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    counters: (f = msg.getCounters()) && protobuf_filter_counters_pb.Counters.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    protobuf_model_merged_pb.CargoTypeResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cargo_type.ListCargoTypesResponse}
 */
proto.cargo_type.ListCargoTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cargo_type.ListCargoTypesResponse;
  return proto.cargo_type.ListCargoTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cargo_type.ListCargoTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cargo_type.ListCargoTypesResponse}
 */
proto.cargo_type.ListCargoTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_counters_pb.Counters;
      reader.readMessage(value,protobuf_filter_counters_pb.Counters.deserializeBinaryFromReader);
      msg.setCounters(value);
      break;
    case 2:
      var value = new protobuf_model_merged_pb.CargoTypeResponse;
      reader.readMessage(value,protobuf_model_merged_pb.CargoTypeResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cargo_type.ListCargoTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cargo_type.ListCargoTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cargo_type.ListCargoTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.ListCargoTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCounters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_counters_pb.Counters.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      protobuf_model_merged_pb.CargoTypeResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Counters counters = 1;
 * @return {?proto.filter.Counters}
 */
proto.cargo_type.ListCargoTypesResponse.prototype.getCounters = function() {
  return /** @type{?proto.filter.Counters} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_counters_pb.Counters, 1));
};


/**
 * @param {?proto.filter.Counters|undefined} value
 * @return {!proto.cargo_type.ListCargoTypesResponse} returns this
*/
proto.cargo_type.ListCargoTypesResponse.prototype.setCounters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cargo_type.ListCargoTypesResponse} returns this
 */
proto.cargo_type.ListCargoTypesResponse.prototype.clearCounters = function() {
  return this.setCounters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cargo_type.ListCargoTypesResponse.prototype.hasCounters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated model.CargoTypeResponse items = 2;
 * @return {!Array<!proto.model.CargoTypeResponse>}
 */
proto.cargo_type.ListCargoTypesResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.model.CargoTypeResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, protobuf_model_merged_pb.CargoTypeResponse, 2));
};


/**
 * @param {!Array<!proto.model.CargoTypeResponse>} value
 * @return {!proto.cargo_type.ListCargoTypesResponse} returns this
*/
proto.cargo_type.ListCargoTypesResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.model.CargoTypeResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.CargoTypeResponse}
 */
proto.cargo_type.ListCargoTypesResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.model.CargoTypeResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cargo_type.ListCargoTypesResponse} returns this
 */
proto.cargo_type.ListCargoTypesResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cargo_type.ExportCargoTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cargo_type.ExportCargoTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cargo_type.ExportCargoTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.ExportCargoTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cargo_type.ExportCargoTypesRequest}
 */
proto.cargo_type.ExportCargoTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cargo_type.ExportCargoTypesRequest;
  return proto.cargo_type.ExportCargoTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cargo_type.ExportCargoTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cargo_type.ExportCargoTypesRequest}
 */
proto.cargo_type.ExportCargoTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cargo_type.ExportCargoTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cargo_type.ExportCargoTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cargo_type.ExportCargoTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.ExportCargoTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Request filter = 1;
 * @return {?proto.filter.Request}
 */
proto.cargo_type.ExportCargoTypesRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 1));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.cargo_type.ExportCargoTypesRequest} returns this
*/
proto.cargo_type.ExportCargoTypesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cargo_type.ExportCargoTypesRequest} returns this
 */
proto.cargo_type.ExportCargoTypesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cargo_type.ExportCargoTypesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cargo_type.ExportCargoTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cargo_type.ExportCargoTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cargo_type.ExportCargoTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.ExportCargoTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_export: (f = msg.getExport()) && protobuf_filter_export_pb.ExportResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cargo_type.ExportCargoTypesResponse}
 */
proto.cargo_type.ExportCargoTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cargo_type.ExportCargoTypesResponse;
  return proto.cargo_type.ExportCargoTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cargo_type.ExportCargoTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cargo_type.ExportCargoTypesResponse}
 */
proto.cargo_type.ExportCargoTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_export_pb.ExportResponse;
      reader.readMessage(value,protobuf_filter_export_pb.ExportResponse.deserializeBinaryFromReader);
      msg.setExport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cargo_type.ExportCargoTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cargo_type.ExportCargoTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cargo_type.ExportCargoTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.ExportCargoTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExport();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_export_pb.ExportResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.ExportResponse export = 1;
 * @return {?proto.filter.ExportResponse}
 */
proto.cargo_type.ExportCargoTypesResponse.prototype.getExport = function() {
  return /** @type{?proto.filter.ExportResponse} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_export_pb.ExportResponse, 1));
};


/**
 * @param {?proto.filter.ExportResponse|undefined} value
 * @return {!proto.cargo_type.ExportCargoTypesResponse} returns this
*/
proto.cargo_type.ExportCargoTypesResponse.prototype.setExport = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cargo_type.ExportCargoTypesResponse} returns this
 */
proto.cargo_type.ExportCargoTypesResponse.prototype.clearExport = function() {
  return this.setExport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cargo_type.ExportCargoTypesResponse.prototype.hasExport = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cargo_type.CreateCargoTypeDocTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cargo_type.CreateCargoTypeDocTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cargo_type.CreateCargoTypeDocTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.CreateCargoTypeDocTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cargoTypeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    docTypeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isRequired: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cargo_type.CreateCargoTypeDocTypeRequest}
 */
proto.cargo_type.CreateCargoTypeDocTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cargo_type.CreateCargoTypeDocTypeRequest;
  return proto.cargo_type.CreateCargoTypeDocTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cargo_type.CreateCargoTypeDocTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cargo_type.CreateCargoTypeDocTypeRequest}
 */
proto.cargo_type.CreateCargoTypeDocTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCargoTypeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocTypeId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cargo_type.CreateCargoTypeDocTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cargo_type.CreateCargoTypeDocTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cargo_type.CreateCargoTypeDocTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.CreateCargoTypeDocTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCargoTypeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocTypeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsRequired();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string cargo_type_id = 1;
 * @return {string}
 */
proto.cargo_type.CreateCargoTypeDocTypeRequest.prototype.getCargoTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cargo_type.CreateCargoTypeDocTypeRequest} returns this
 */
proto.cargo_type.CreateCargoTypeDocTypeRequest.prototype.setCargoTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string doc_type_id = 2;
 * @return {string}
 */
proto.cargo_type.CreateCargoTypeDocTypeRequest.prototype.getDocTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cargo_type.CreateCargoTypeDocTypeRequest} returns this
 */
proto.cargo_type.CreateCargoTypeDocTypeRequest.prototype.setDocTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_required = 3;
 * @return {boolean}
 */
proto.cargo_type.CreateCargoTypeDocTypeRequest.prototype.getIsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cargo_type.CreateCargoTypeDocTypeRequest} returns this
 */
proto.cargo_type.CreateCargoTypeDocTypeRequest.prototype.setIsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cargo_type.UpdateCargoTypeDocTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cargo_type.UpdateCargoTypeDocTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cargo_type.UpdateCargoTypeDocTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.UpdateCargoTypeDocTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cargoTypeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    docTypeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isRequired: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cargo_type.UpdateCargoTypeDocTypeRequest}
 */
proto.cargo_type.UpdateCargoTypeDocTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cargo_type.UpdateCargoTypeDocTypeRequest;
  return proto.cargo_type.UpdateCargoTypeDocTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cargo_type.UpdateCargoTypeDocTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cargo_type.UpdateCargoTypeDocTypeRequest}
 */
proto.cargo_type.UpdateCargoTypeDocTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCargoTypeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocTypeId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cargo_type.UpdateCargoTypeDocTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cargo_type.UpdateCargoTypeDocTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cargo_type.UpdateCargoTypeDocTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cargo_type.UpdateCargoTypeDocTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCargoTypeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocTypeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsRequired();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.cargo_type.UpdateCargoTypeDocTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cargo_type.UpdateCargoTypeDocTypeRequest} returns this
 */
proto.cargo_type.UpdateCargoTypeDocTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cargo_type_id = 2;
 * @return {string}
 */
proto.cargo_type.UpdateCargoTypeDocTypeRequest.prototype.getCargoTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cargo_type.UpdateCargoTypeDocTypeRequest} returns this
 */
proto.cargo_type.UpdateCargoTypeDocTypeRequest.prototype.setCargoTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string doc_type_id = 3;
 * @return {string}
 */
proto.cargo_type.UpdateCargoTypeDocTypeRequest.prototype.getDocTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cargo_type.UpdateCargoTypeDocTypeRequest} returns this
 */
proto.cargo_type.UpdateCargoTypeDocTypeRequest.prototype.setDocTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_required = 4;
 * @return {boolean}
 */
proto.cargo_type.UpdateCargoTypeDocTypeRequest.prototype.getIsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cargo_type.UpdateCargoTypeDocTypeRequest} returns this
 */
proto.cargo_type.UpdateCargoTypeDocTypeRequest.prototype.setIsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


goog.object.extend(exports, proto.cargo_type);
