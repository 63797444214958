/**
 * @fileoverview gRPC-Web generated client stub for cargo_type
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/cargo_type/category.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_cargo_type_category_pb from '../../protobuf/cargo_type/category_pb';
import * as protobuf_model_merged_pb from '../../protobuf/model/merged_pb';


export class CategoryServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/cargo_type.CategoryService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_cargo_type_category_pb.CreateCategoryRequest,
    protobuf_model_merged_pb.CategoryResponse,
    (request: protobuf_cargo_type_category_pb.CreateCategoryRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.CategoryResponse.deserializeBinary
  );

  create(
    request: protobuf_cargo_type_category_pb.CreateCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.CategoryResponse>;

  create(
    request: protobuf_cargo_type_category_pb.CreateCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CategoryResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.CategoryResponse>;

  create(
    request: protobuf_cargo_type_category_pb.CreateCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cargo_type.CategoryService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cargo_type.CategoryService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/cargo_type.CategoryService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_cargo_type_category_pb.UpdateCategoryRequest,
    protobuf_model_merged_pb.CategoryResponse,
    (request: protobuf_cargo_type_category_pb.UpdateCategoryRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.CategoryResponse.deserializeBinary
  );

  update(
    request: protobuf_cargo_type_category_pb.UpdateCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.CategoryResponse>;

  update(
    request: protobuf_cargo_type_category_pb.UpdateCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CategoryResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.CategoryResponse>;

  update(
    request: protobuf_cargo_type_category_pb.UpdateCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cargo_type.CategoryService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cargo_type.CategoryService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/cargo_type.CategoryService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_cargo_type_category_pb.GetCategoriesRequest,
    protobuf_cargo_type_category_pb.GetCategoriesResponse,
    (request: protobuf_cargo_type_category_pb.GetCategoriesRequest) => {
      return request.serializeBinary();
    },
    protobuf_cargo_type_category_pb.GetCategoriesResponse.deserializeBinary
  );

  get(
    request: protobuf_cargo_type_category_pb.GetCategoriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_cargo_type_category_pb.GetCategoriesResponse>;

  get(
    request: protobuf_cargo_type_category_pb.GetCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_cargo_type_category_pb.GetCategoriesResponse) => void): grpcWeb.ClientReadableStream<protobuf_cargo_type_category_pb.GetCategoriesResponse>;

  get(
    request: protobuf_cargo_type_category_pb.GetCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_cargo_type_category_pb.GetCategoriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cargo_type.CategoryService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cargo_type.CategoryService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/cargo_type.CategoryService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_cargo_type_category_pb.DeleteCategoriesRequest,
    protobuf_cargo_type_category_pb.DeleteCategoriesResponse,
    (request: protobuf_cargo_type_category_pb.DeleteCategoriesRequest) => {
      return request.serializeBinary();
    },
    protobuf_cargo_type_category_pb.DeleteCategoriesResponse.deserializeBinary
  );

  delete(
    request: protobuf_cargo_type_category_pb.DeleteCategoriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_cargo_type_category_pb.DeleteCategoriesResponse>;

  delete(
    request: protobuf_cargo_type_category_pb.DeleteCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_cargo_type_category_pb.DeleteCategoriesResponse) => void): grpcWeb.ClientReadableStream<protobuf_cargo_type_category_pb.DeleteCategoriesResponse>;

  delete(
    request: protobuf_cargo_type_category_pb.DeleteCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_cargo_type_category_pb.DeleteCategoriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cargo_type.CategoryService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cargo_type.CategoryService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/cargo_type.CategoryService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_cargo_type_category_pb.ListCategoriesRequest,
    protobuf_cargo_type_category_pb.ListCategoriesResponse,
    (request: protobuf_cargo_type_category_pb.ListCategoriesRequest) => {
      return request.serializeBinary();
    },
    protobuf_cargo_type_category_pb.ListCategoriesResponse.deserializeBinary
  );

  list(
    request: protobuf_cargo_type_category_pb.ListCategoriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_cargo_type_category_pb.ListCategoriesResponse>;

  list(
    request: protobuf_cargo_type_category_pb.ListCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_cargo_type_category_pb.ListCategoriesResponse) => void): grpcWeb.ClientReadableStream<protobuf_cargo_type_category_pb.ListCategoriesResponse>;

  list(
    request: protobuf_cargo_type_category_pb.ListCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_cargo_type_category_pb.ListCategoriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cargo_type.CategoryService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cargo_type.CategoryService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorExport = new grpcWeb.MethodDescriptor(
    '/cargo_type.CategoryService/Export',
    grpcWeb.MethodType.UNARY,
    protobuf_cargo_type_category_pb.ExportCategoriesRequest,
    protobuf_cargo_type_category_pb.ExportCategoriesResponse,
    (request: protobuf_cargo_type_category_pb.ExportCategoriesRequest) => {
      return request.serializeBinary();
    },
    protobuf_cargo_type_category_pb.ExportCategoriesResponse.deserializeBinary
  );

  export(
    request: protobuf_cargo_type_category_pb.ExportCategoriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_cargo_type_category_pb.ExportCategoriesResponse>;

  export(
    request: protobuf_cargo_type_category_pb.ExportCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_cargo_type_category_pb.ExportCategoriesResponse) => void): grpcWeb.ClientReadableStream<protobuf_cargo_type_category_pb.ExportCategoriesResponse>;

  export(
    request: protobuf_cargo_type_category_pb.ExportCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_cargo_type_category_pb.ExportCategoriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cargo_type.CategoryService/Export',
        request,
        metadata || {},
        this.methodDescriptorExport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cargo_type.CategoryService/Export',
    request,
    metadata || {},
    this.methodDescriptorExport);
  }

}

